document.addEventListener("DOMContentLoaded", () => {
  const sections = document.querySelectorAll(".section");
  const navItems = document.querySelectorAll(".sections-nav-item");

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const id = entry.target.id;
      const navItem = document.querySelector(
        `.sections-nav-item[href="#${id}"]`
      );

      if (entry.isIntersecting) {
        navItem.classList.add("active");
      } else {
        navItem.classList.remove("active");
      }
    });
  }, observerOptions);

  sections.forEach((section) => {
    observer.observe(section);
  });
});
